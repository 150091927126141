import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import CustomSwitch from "components/CustomSwitch";
import CustomDialogBox from "components/CustomDialogBox/CustomDialogBox";
import { toggleSidebar } from "store/actions";
import { generateInputs, validationSchema, initialValues, handleSingleCreatorCreation } from "./CreateSingleCreator";
import { generateCreditsInputs, validationSchemaCredits, initialValuesCredits, handleAddCredits } from "./AddfunqCredits";
import { listAllCreators, resetCreatorsData } from "store/actions";
import { updateCreatorAccess } from "store/actions";

const useStyles = makeStyles(componentStyles);

function Creators() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isAddCredits, setIsAddCredits] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [showDialog, setShowDialog] = useState(false);

    const { creatorsData, showViewMore } = useSelector(
        ({
            creators: {
                creators: { list, showViewMore },
            },
        }) => ({
            creatorsData: list,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetCreatorsData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllCreators());
    };

    const handleToggleChange = (data) => {
        setSelectedUser(data._id);
        setShowDialog(true);
    };

    const handleDialogBoxSubmit = () => {
        setShowDialog(false);
        dispatch(updateCreatorAccess({ userId: selectedUser }));
    };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                <Grid container justify="flex-start" spacing={3}>
                    <Grid item style={{ width: "100%" }}>
                        <Card style={{ backgroundColor: "grey" }}>
                            <Grid container spacing={3}>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <CustomIconButton
                                        title="Add new"
                                        icon={<PersonAddIcon />}
                                        onClick={() => {
                                            dispatch(toggleSidebar(true));
                                            setIsAddCredits(false);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <CustomTable
                    title="Creators"
                    columns={[
                        {
                            title: "Actions",
                            render: (rowData) => (
                                <CustomTextButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setSelectedUser(rowData._id);
                                        dispatch(toggleSidebar(true));
                                        setIsAddCredits(true);
                                    }}
                                    title="Give Credits"
                                    size="small"
                                />
                            ),
                        },
                        {
                            title: "Creator Access",
                            render: (rowData) => (
                                <CustomSwitch checked={rowData?.is_creator} onChange={() => handleToggleChange(rowData)} />
                            ),
                        },
                        { title: "Name", field: "name" },
                        { title: "Email", field: "email" },
                        { title: "Wallet ID", render: (rowData) => <p>{rowData?.wallet_id?.wallet_id}</p> },
                    ]}
                    data={creatorsData}
                    showViewMoreButton={showViewMore}
                    viewMoreButtonClick={fetchMoreData}
                />
            </Container>
            <CustomSidebar
                title={isAddCredits ? "Add Credits" : "Add Creator"}
                inputs={isAddCredits ? generateCreditsInputs() : generateInputs()}
                validationSchema={isAddCredits ? validationSchemaCredits : validationSchema}
                initialValues={isAddCredits ? initialValuesCredits({ userId: selectedUser }) : initialValues}
                handleSubmit={isAddCredits ? handleAddCredits : handleSingleCreatorCreation}
            />
            <CustomDialogBox
                open={showDialog}
                handleClose={() => setShowDialog(false)}
                title="Are you sure want to continue?"
                handleSubmitButton={handleDialogBoxSubmit}
            />
        </>
    );
}

export default Creators;
