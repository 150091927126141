import { toast } from "react-toastify";
import { TOGGLE_LOADING, LIST_ALL_WALLETS, RESET_WALLET_LIST_DATA } from "../types";
import { unAuthorizedPost, post } from "services";
import { FETCH_ALL_WALLETS, CREATE_NEW_WALLET} from "graphql";

export const listAllWallets = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().wallets;

        let apiResponse = await post("", {
            query: FETCH_ALL_WALLETS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_wallets: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_WALLETS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetWalletsData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: RESET_WALLET_LIST_DATA });
    };
};


export const createNewWallet = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });


        let apiResponse = await post("", {
            query: CREATE_NEW_WALLET,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {

            toast.success("Wallet Created");
            dispatch(resetWalletsData());
            dispatch(listAllWallets());
        }
    };
};
