import * as Yup from "yup";
import { store } from "store/store";
import { toggleSidebar,createNewWallet } from "store/actions";

export const handleSingleWalletCreation = (value) => {
    store.dispatch(toggleSidebar(false));
    store.dispatch(createNewWallet(value));
};

export const generateInputs = () => {

    const inputs = [
        {
            name: "privateKey",
            label: "Private key",
            type: "text",
            placeHolder: "Enter private key",
            visibility: true,
        }
    ];
    return inputs;
};

export const validationSchema = Yup.object().shape({
    privateKey: Yup.string().max(255).required("Private Key is required"),
});

export const initialValues = {
    privateKey: "",
};
