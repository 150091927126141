import * as Yup from "yup";
import { store } from "store/store";
import { toggleSidebar, createNewCreator, apiCall } from "store/actions";
import { FETCH_WALLETS_FOR_DROPDOWN } from "graphql";

export const handleSingleCreatorCreation = (value) => {
    store.dispatch(toggleSidebar(false));
    if (value.walletId.length) {
        value.walletId = value.walletId[0].value;
    } else {
        value.walletId = "";
    }
    store.dispatch(createNewCreator(value));
};

export const generateInputs = () => {
    const fetchWallets = async () => {
        let options = [];
        let apiResponse = await apiCall(FETCH_WALLETS_FOR_DROPDOWN);
        if (apiResponse.status) {
            let {
                list_wallets_for_dropdown: { data },
            } = apiResponse.data;

            options = data.map((e, i) => {
                return {
                    value: e?._id,
                    label: e?.wallet_id,
                    optionLabel: (
                        <div>
                            <p style={{ fontSize: 13, fontFamily: "inherit", fontWeight: "bold" }}>{e?.wallet_id}</p>
                        </div>
                    ),
                };
            });
        }
        return options;
    };

    const inputs = [
        {
            name: "name",
            label: "Name",
            type: "text",
            placeHolder: "Enter name",
            visibility: true,
        },
        {
            name: "walletId",
            label: "Wallet",
            type: "asyncDropdown",
            placeHolder: "Select Wallet",
            visibility: true,
            options: () => fetchWallets(),
            noOptionsMessage: "No wallets available",
        },
        {
            name: "email",
            label: "email",
            type: "text",
            placeHolder: "Enter email ID",
            visibility: true,
        },
    ];
    return inputs;
};

export const validationSchema = Yup.object().shape({
    // name: Yup.string().max(255).required("Name is required"),
    // walletId: Yup.array().min(1, "Wallet is required"),
    email: Yup.string().email("Invalid email address").required("email ID is required"),
});

export const initialValues = {
    name: "",
    walletId: [],
    email: "",
};
