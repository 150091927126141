import * as Yup from "yup";
import { store } from "store/store";
import { toggleSidebar, addFunqCredits } from "store/actions";

export const handleAddCredits = (value) => {
    store.dispatch(toggleSidebar(false));
    store.dispatch(addFunqCredits(value));
};

export const generateCreditsInputs = () => {
    const inputs = [
        {
            name: "credits",
            label: "Credits",
            type: "number",
            placeHolder: "Enter credits",
            visibility: true,
            inputProps: {
                min: 1,
                max: 50,
            },
        },
        {
            name: "userId",
            label: "user",
            type: "text",
            visibility: false,
        },
    ];
    return inputs;
};

export const validationSchemaCredits = Yup.object().shape({
    credits: Yup.number().max(50).min(1).required("Credits is required"),
});

export const initialValuesCredits = ({ userId }) => {
    return { credits: 1, userId: userId };
};
