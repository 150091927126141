import { LIST_ALL_NFTS, RESET_NFT_LIST_DATA } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    details: {},
};

const nftsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_NFTS:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case RESET_NFT_LIST_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default nftsReducer;
