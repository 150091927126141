export const FETCH_ALL_CREATORS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_creators(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      name
      email
      img
      is_creator
      wallet_id {
        _id
        wallet_id
      }
    }
    hasMore
  }
}

`;

export const CREATE_NEW_CREATOR = `mutation ($name: String, $walletId: String, $email: String!) {
  add_new_creator(name: $name, wallet_id: $walletId, email: $email) {
    message
  }
}
`;

export const FETCH_CREATORS_FOR_DROPDOWN = `query ($status: String) {
  list_creators_for_dropdown(status: $status) {
    message
    data {
      _id
      name
      email
      img
      wallet_id {
        _id
        wallet_id
      }
    }
  }
}
`;

export const ADD_FUNQ_CREDITS = `mutation ($credits: Int!, $userId: String!) {
  add_funq_credits(credits: $credits, user_id: $userId) {
    message
  }
}
`;

export const UPDATE_CREATOR_ACCESS = `mutation ($userId: String!) {
  update_creator_access(user_id: $userId) {
    message
  }
}
`;
