import { toast } from "react-toastify";
import { TOGGLE_LOADING, LIST_CREATORS_DATA, RESET_CREATORS_LIST_DATA, UPDATE_CREATOR_STATUS } from "../types";
import { post } from "services";
import { FETCH_ALL_CREATORS, CREATE_NEW_CREATOR, ADD_FUNQ_CREDITS, UPDATE_CREATOR_ACCESS } from "graphql";

export const listAllCreators = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            creators: { pageNumber, pageSize, list },
        } = getState().creators;

        let apiResponse = await post("", {
            query: FETCH_ALL_CREATORS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_creators: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_CREATORS_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetCreatorsData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: RESET_CREATORS_LIST_DATA });
    };
};

export const createNewCreator = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        // const {
        //     creators: { limit, offset, list },
        // } = getState().creators;

        let apiResponse = await post("", {
            query: CREATE_NEW_CREATOR,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            // let {
            //     add_new_creator: { message },
            // } = apiResponse.data.data;

            toast.success("Creator Added");
            dispatch(resetCreatorsData());
            dispatch(listAllCreators());
        }
    };
};

export const addFunqCredits = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_FUNQ_CREDITS,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            toast.success("Credits Added");
        }
    };
};

export const updateCreatorAccess = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_CREATOR_ACCESS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: UPDATE_CREATOR_STATUS, payload: requestData.userId });
            toast.success("Updated Successfully");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
