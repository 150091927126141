export const FETCH_ALL_WALLETS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_wallets(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      wallet_id
      is_linked
      user_id {
        _id
        name
        email
      }
    }
    hasMore
  }
}`;

export const FETCH_WALLETS_FOR_DROPDOWN = `query{
    list_wallets_for_dropdown{
      message
      data {
        _id
        wallet_id
        is_linked
      }
    }
  }`;

export const CREATE_NEW_WALLET = `mutation($privateKey: String!){
  create_new_wallet(private_key: $privateKey){
    message
  }
}`;

export const FETCH_WALLET_PRIVATE_KEY = `query ($otp: String!, $walletId: String!) {
  show_private_key(otp: $otp, wallet_id: $walletId) {
    message
    private_key
  }
}
`;

export const SEND_OTP = `mutation {
  send_otp {
    message
  }
}
`;

export const FETCH_LEDGER_WALLET_DETAILS = `query{
  list_ledger_details{
    wallet_address
    wallet_balance
  }
}`;
