import { LIST_CREATORS_DATA, RESET_CREATORS_LIST_DATA, UPDATE_CREATOR_STATUS } from "../types";

const INITIAL_STATE = {
    creators: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    creatorDetails: {},
    filteredData: [],
};

const creatorsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_CREATORS_DATA:
            return {
                ...state,
                creators: { pageSize: 10, ...payload },
            };

        case RESET_CREATORS_LIST_DATA:
            return INITIAL_STATE;

        case UPDATE_CREATOR_STATUS:
            state.creators.list.map((e) => {
                if (e._id == payload) {
                    e.is_creator = !e.is_creator;
                }
            });
            return { ...state };
        default:
            return state;
    }
};

export default creatorsReducer;
