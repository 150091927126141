import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, Backdrop, CircularProgress, FilledInput, Chip } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import { apiCallAuthorized } from "store/actions";
import { SEND_OTP } from "graphql";
import { FETCH_WALLET_PRIVATE_KEY } from "graphql";

const ModalContent = ({ walletId }) => {
    const [activeStep, setActiveStep] = useState("1");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [copied, setCopied] = useState(false);

    const handleSendOtp = async () => {
        setLoading(true);
        let response = await apiCallAuthorized(SEND_OTP);

        if (response.status) {
            setActiveStep("2");
            setLoading(false);
            return;
        }
        return setLoading(false);
    };

    const handleSubmitbutton = async () => {
        setLoading(true);
        let response = await apiCallAuthorized(FETCH_WALLET_PRIVATE_KEY, { otp: otp, walletId: walletId });

        if (response.status) {
            const {
                show_private_key: { private_key },
            } = response.data;
            setPrivateKey(private_key);
            setActiveStep("3");
            setLoading(false);
            return;
        }

        return setLoading(false);
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            {activeStep === "1" ? (
                <>
                    <Grid container justify="center">
                        <Grid item>
                            <h4>Click on below button to send OTP to security email</h4>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item>
                            <CustomTextButton title="send OTP" color="primary" onClick={() => handleSendOtp()} />
                        </Grid>
                    </Grid>
                </>
            ) : activeStep === "2" ? (
                <>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <h4>Enter OTP received on security email</h4>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item style={{ backgroundColor: "whitesmoke" }}>
                            <label>OTP</label>
                            <FilledInput
                                name="otp"
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <CustomTextButton
                                title="submit"
                                onClick={() => handleSubmitbutton()}
                                color="primary"
                                disabled={otp === ""}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <h4>Private Key of selected wallet is</h4>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <Chip label={privateKey} />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <CopyToClipboard text={privateKey} onCopy={() => setCopied(true)}>
                                <button>Copy to clipboard</button>
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>{copied ? <span style={{ color: "red" }}>Copied</span> : null}</Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ModalContent;
