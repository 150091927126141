export const FETCH_ALL_NFTS = `query ($pageSize: Int, $pageNumber: Int, $status: String, $creator: String) {
  list_all_nfts(page_size: $pageSize, page_number: $pageNumber, status: $status, creator:$creator) {
    message
    data {
      _id
      name
      description
      art_url
      royalty_percentage
      nft_id
      status
      creator_id {
        _id
        name
        email
        wallet_id {
          _id
          wallet_id
        }
      }
    }
    hasMore
  }
}
`;

export const CREATE_NEW_NFT = `mutation ($name: String!, $description: String!, $creatorWalletId: String!, $artWork: String!, $maximumSupply: Int!, $royaltyPercentage: Float) {
  create_new_nft(name: $name, description: $description, creator_wallet_id: $creatorWalletId, file: $artWork, maximum_supply: $maximumSupply, royalty_percentage: $royaltyPercentage) {
    message
  }
}
`;

export const CREATE_NEW_NFT_DATA = `mutation ($name: String!, $description: String!, $creatorWalletId: String!, $artWork: String!, $maximumSupply: Int!, $royaltyPercentage: Float, $nftId: String!) {
  create_new_nft_data(name: $name, description: $description, creator_wallet_id: $creatorWalletId, file_url: $artWork, maximum_supply: $maximumSupply, royalty_percentage: $royaltyPercentage, nft_id: $nftId) {
    message
    nft_id
  }
}
`;

export const UPDATE_NFT_DATA = `mutation ($id: String!, $nftId: String!, $notify: Boolean) {
  update_nft_data(_id: $id, nft_id: $nftId, notify: $notify) {
    message
  }
}`;
