import { toast } from "react-toastify";
import { TOGGLE_LOADING, LIST_ALL_NFTS, RESET_NFT_LIST_DATA } from "../types";
import { unAuthorizedPost, post } from "services";
import { FETCH_ALL_NFTS, CREATE_NEW_NFT, CREATE_NEW_NFT_DATA, UPDATE_NFT_DATA } from "graphql";

export const listAllNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().nfts;

        let apiResponse = await post("", {
            query: FETCH_ALL_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "all",
                creator: requestData?.creator ?? "all",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetNftsData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: RESET_NFT_LIST_DATA });
    };
};

export const createNewNft = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        // const {
        //     creators: { limit, offset, list },
        // } = getState().creators;

        let apiResponse = await unAuthorizedPost("", {
            query: CREATE_NEW_NFT,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            // let {
            //     create_new_nft: { message },
            // } = apiResponse.data.data;

            toast.success("NFT Created");
            dispatch(resetNftsData());
            dispatch(listAllNfts());
        }
    };
};

export const createNewNftData = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        // const {
        //     creators: { limit, offset, list },
        // } = getState().creators;

        let apiResponse = await unAuthorizedPost("", {
            query: CREATE_NEW_NFT_DATA,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            // let {
            //     create_new_nft_data: { message },
            // } = apiResponse.data.data;

            toast.success("NFT Created");
            dispatch(resetNftsData());
            dispatch(listAllNfts());
        }
    };
};

export const updateNftData = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        // const {
        //     creators: { limit, offset, list },
        // } = getState().creators;

        let apiResponse = await unAuthorizedPost("", {
            query: UPDATE_NFT_DATA,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            // let {
            //     create_new_nft_data: { message },
            // } = apiResponse.data.data;

            toast.success("NFT Updated");
            // dispatch(resetNftsData());
            // dispatch(listAllNfts());
        }
    };
};
