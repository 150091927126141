import * as Yup from "yup";
import { Connection, actions } from "@metaplex/js";
import { toast } from "react-toastify";
import { store } from "store/store";
import { toggleSidebar, createNewNftData, apiCall } from "store/actions";
import { FETCH_ALL_CREATORS, FILE_UPLOAD } from "graphql";
import { SOL_LEDGER_ACCOUNT, SOLANA_CLUSTER, getMetadata } from "helpers";
import { getBase64 } from "utils";
import { toggleLoading } from "store/actions";

export const handleSingleNft = async (value) => {
    store.dispatch(toggleLoading(true));

    try {
        let base64img = await getBase64(value.artWork[0]);
        let imageUpload = await apiCall(FILE_UPLOAD, { file: base64img });

        let {
            upload_file: { url },
        } = imageUpload.data;

        let imageUrl = url;

        let metadata = getMetadata({
            name: value.name,
            description: value.description,
            royaltyPercentage: value.royaltyPercentage * 100,
            imageUrl: imageUrl,
            imageType: `image/${base64img.split(";")[0].split("/")[1]}`,
            category: "image",
            creators: [{ address: SOL_LEDGER_ACCOUNT, share: 100 }],
        });

        let objJsonStr = JSON.stringify(metadata);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

        let metadataUpload = await apiCall(FILE_UPLOAD, { file: objJsonB64, fileType: "json" });
        let metadataUrl = metadataUpload.data?.upload_file?.url;

        const connection = new Connection(SOLANA_CLUSTER);
        const resp = await window.solana.connect();

        const NftMint = await actions.mintNFT({
            connection: connection,
            wallet: window.solana,
            uri: metadataUrl,
            maxSupply: 1,
        });

        value.creatorWalletId = value.creatorWalletId[0].value;
        value.artWork = imageUrl;
        value.nftId = NftMint.mint.toString();

        store.dispatch(createNewNftData(value));
        store.dispatch(toggleSidebar(false));
    } catch (err) {
        store.dispatch(toggleLoading(false));
        store.dispatch(toggleSidebar(false));
        toast.error(err.message);
    }

    // let base64img = await getBase64(value.artWork[0]);
    // value.creatorWalletId = value.creatorWalletId[0].value;
    // value.artWork = base64img;
    // store.dispatch(toggleSidebar(false));
    // store.dispatch(createNewNft(value));
};

export const generateInputs = () => {
    const fetchCreators = async () => {
        let options = [];
        let apiResponse = await apiCall(FETCH_ALL_CREATORS);
        if (apiResponse.status) {
            let {
                list_all_creators: { data },
            } = apiResponse.data;

            options = data.map((e, i) => {
                return {
                    value: e?.wallet_id,
                    label: e?.name,
                    optionLabel: (
                        <div>
                            <p style={{ fontSize: 13, fontFamily: "inherit", fontWeight: "bold" }}>{e?.name}</p>
                            <p style={{ fontSize: 13, fontFamily: "inherit", fontWeight: "bold" }}>{e?.wallet_id}</p>
                        </div>
                    ),
                };
            });
        }
        return options;
    };
    const inputs = [
        {
            name: "name",
            label: "Name",
            type: "text",
            placeHolder: "Enter name",
            visibility: true,
        },
        {
            name: "description",
            label: "Description",
            type: "text",
            placeHolder: "Enter description",
            visibility: true,
        },
        {
            name: "creatorWalletId",
            label: "Creator",
            type: "asyncDropdown",
            placeHolder: "Select Creator",
            visibility: true,
            options: () => fetchCreators(),
            noOptionsMessage: "No creators available",
        },
        {
            name: "artWork",
            label: "Art work",
            type: "uploader",
            placeHolder: "Art",
            visibility: true,
        },
        {
            name: "maximumSupply",
            label: "Maximum Supply",
            type: "number",
            placeHolder: "Enter Maximum Supply",
            visibility: true,
            inputProps: {
                min: 1,
            },
            disabled: true,
        },
        {
            name: "royaltyPercentage",
            label: "Royalty in Percentage",
            type: "number",
            placeHolder: "Enter Royalty Percentage",
            visibility: true,
            inputProps: {
                min: 0,
                max: 100,
            },
        },
    ];
    return inputs;
};

export const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Name is required"),
    creatorWalletId: Yup.array().min(1, "Creator is required"),
    artWork: Yup.array().min(1, "Artwork is required"),
    royaltyPercentage: Yup.number().min(0, "Min value 0.").max(100, "Max value 100."),
});

export const initialValues = {
    name: "",
    description: "",
    creatorWalletId: [],
    artWork: [],
    maximumSupply: 1,
    royaltyPercentage: 0,
};
