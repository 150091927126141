export const getMetadata = (data) => {
    return {
        name: data.name,
        symbol: "",
        description: data.description,
        seller_fee_basis_points: data.royaltyPercentage,
        image: data.imageUrl,
        external_url: "",
        properties: {
            files: [{ uri: data.imageUrl, type: data.imageType }],
            category: data.category,
            creators: data.creators,
        },
    };
};
