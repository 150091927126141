export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";

export const REDIRECT_TO = "REDIRECT_TO";

export const LIST_CREATORS_DATA = "LIST_CREATORS_DATA";
export const RESET_CREATORS_LIST_DATA = "RESET_CREATORS_LIST_DATA";

export const LIST_ALL_NFTS = "LIST_ALL_NFTS";
export const RESET_NFT_LIST_DATA = "RESET_NFT_LIST_DATA";

export const LIST_ALL_WALLETS = "LIST_ALL_WALLETS";
export const RESET_WALLET_LIST_DATA = "RESET_WALLET_LIST_DATA";

export const UPDATE_CREATOR_STATUS = "UPDATE_CREATOR_STATUS";
